import React from "react"
import parser from "html-react-parser"
import GravityFormForm from "gatsby-plugin-gravity-forms"
import "./gatedForm.scss"

function GatedForm({
  data,
  formTitle,
  formDescription,
  successCallback,
  pageTitle,
}) {
  return (
    <div className="gated-content">
      <div className="container">
        <div className="content-wrapper">
          <div className="form-title">
            <h1>{formTitle ? formTitle : "Whitepapers Download"}</h1>
            {formDescription && <div>{parser(formDescription)}</div>}
          </div>
        </div>
        <div className="form-wrapper">
          <GravityFormForm
            data={data}
            successCallback={successCallback}
            presetValues={{ input_7: pageTitle }}
          />
        </div>
      </div>
    </div>
  )
}

export default GatedForm
